import { addObject, detailObject, fetchObjects, saveObject } from './crudFunctions';

export const getAllAppointments = async (filters = {}) => {
  let url = '/api/appointments/';

  const queryParams = Object.entries({ ...filters })
    .filter(([_, value]) => value !== undefined && value !== null && value !== '')
    .map(([key, value]) => {
      if (key === 'start_from' || key === 'start_to' || key === 'expected_end_from' || key === 'expected_end_to') {
        // Convert milliseconds to seconds for the API
        const timestampInSeconds = Math.floor(value / 1000);
        return `${key}=${encodeURIComponent(timestampInSeconds)}`;
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .filter(Boolean)
    .join('&');

  if (queryParams) {
    url += `?${queryParams}`;
  }

  try {
    const res = await fetchObjects(url);
    return res;
  } catch (error) {
    console.error('Error fetching sessions:', error);
    return [];
  }
};

const getApiUrl = (appointmentId) => {
  if (appointmentId) {
    return `/api/appointments/${appointmentId}/`;
  }

  return '/api/appointments/';
};

const fetchAppointments = async (filterParam = '') => {
  return await fetchObjects(`${getApiUrl()}${filterParam}`);
};

export const getAppointments = async (filterParam) => {
  const dataFromServer = await fetchAppointments(filterParam);
  const appointmentsRecords = dataFromServer.results || [];
  return appointmentsRecords;
};

export const addAppointment = async (appointmentData) => {
  const newAppointment = await addObject(getApiUrl(), appointmentData);
  if (typeof newAppointment == 'object') {
    return newAppointment;
  }
};

export const saveAppointment = async (appointmentId, appointmentData) => {
  delete appointmentData.id;
  const existentAppointment = await saveObject(getApiUrl(appointmentId), appointmentData);
  if (typeof existentAppointment == 'object') {
    getAppointments();
    return existentAppointment;
  }
};

export const editAppointment = async (appointmentId) => {
  Array.from(document.querySelectorAll('.is-invalid')).forEach((el) => el.classList.remove('is-invalid'));
  const res = await detailObject(getApiUrl(appointmentId), 'GET');
  const appointmentObject = await res.json();

  for (const fieldname in appointmentObject) {
    const field_element = document.getElementById('edit_field_' + fieldname);
    if (fieldname === 'start' || fieldname === 'expected_end') {
      let datetime_field = appointmentObject[fieldname].split(' ');
      let field_date = document.getElementById('edit_field_' + fieldname + '_date');
      let field_time = document.getElementById('edit_field_' + fieldname + '_time');
      if (field_date && field_time) {
        field_date.value = datetime_field[0];
        field_time.value = datetime_field[1];
      }
    }
    if (field_element) {
      field_element.value = appointmentObject[fieldname];
    }
  }
  return appointmentObject;
};

export const deleteAppointment = async (appointmentId) => {
  if (window.confirm('Are you sure you want to delete this appointment?') === true) {
    await detailObject(getApiUrl(appointmentId), 'DELETE');
    return true;
  } else {
    return false;
  }
};

export const getCurrentAppointment = async (questionnaire) => {
  const currentAppointment = await detailObject(getApiUrl('current'));
  const response = await currentAppointment.json();
  return response;
};

export const getAppointment = async (appointmentId) => {
  const currentAppointment = await detailObject(getApiUrl(appointmentId));
  const response = await currentAppointment.json();
  return response;
};
